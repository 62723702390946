import "./App.css";
import { Box, Button, Typography } from "@mui/material";
import asset1 from "./image/asset1.png";
import asset2 from "./image/asset2.png";
import bgpng from "./image/bg.png";
import styled from "styled-components";

const App = () => {
  return (
    <div className="app">
      <Box
        className="App-header"
        sx={{
          backgroundImage: `url(${bgpng})`,
          backgroundSize: "cover",
          color: "#27374D",
          overflow: "scroll",
        }}
      >
        <BoxContent>
          <FirstBox>
            <TitleBox>
              <H1Typography sx={{ mr: 2, fontWeight: "800" }}>
                {"ขายฝากใจดี"}
              </H1Typography>
              <Typography
                className="h3-typo"
                variant="h3"
                sx={{ fontFamily: "Kanit" }}
              >
                {"By Smartfinn"}
              </Typography>
            </TitleBox>
            <Typography
              variant="h5"
              sx={{ fontFamily: "Kanit", textAlign: "center", p: 2 }}
            >
              {"ปลอดภัย ตามกฎหมาย ไม่ยึดทรัพย์ อีกหนึ่งทางเลือกเพื่อ SME ไทย"}
            </Typography>
          </FirstBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 2,
            }}
          >
            <a href="https://page.line.me/bif4323y?openQrModal=true">
              <TopButton>แอดไลน์</TopButton>
            </a>
            <H3Typography>{"หรือ"}</H3Typography>
            <a href="tel:+660896645359">
              <TopButton>089-664-5359</TopButton>
            </a>
          </Box>
          <ThirdBox>
            <Typography variant="h4" sx={{ mt: 1, fontFamily: "Kanit" }}>
              {"เหมาะสำหรับ"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontFamily: "Kanit" }}>
                <ul>
                  <li>ผู้ที่ต้องการเงินทุนหมุนเวียน ขยายธุรกิจ</li>
                  <li>ไถ่ถอนหลักทรัพย์</li>
                  <li>สร้างรายการเดินบัญชีเพื่อใช้บริการธนาคาร</li>
                  <li>แก้หนี้เดิม ด้วยดอกเบี้ยที่ต่ำกว่า</li>
                  <li>กู้แบงค์ไม่ผ่าน</li>
                  <li>ต้องการวงเงินเพิ่ม</li>
                </ul>
              </Typography>
            </Box>
          </ThirdBox>
        </BoxContent>
      </Box>
      <BoxPicture sx={{ left: "0" }}>
        <img src={asset1} style={{ width: "100%" }} alt="picLeft" />
      </BoxPicture>
      <BoxPicture sx={{ right: 0 }}>
        <img src={asset2} style={{ width: "100%" }} alt="picRight" />
      </BoxPicture>
    </div>
  );
};

const BoxContent = styled(Box)`
  width: 100%;
  height: 100vh;
  padding-top: 10px;
  @media screen and (min-height: 600px) and (min-width: 635px) {
    padding-top: unset;
    height: 600px;
  }
`;

const TitleBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  flex-direction: column;
  @media screen and (min-width: 635px) {
    flex-direction: row;
  }
`;

const TopButton = styled(Button)`
  font-family: "Kanit!important";
  background-color: #146c94 !important;
  padding: 10px !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  @media screen and (min-width: 425px) {
    font-size: 1.2em !important;
  }
  @media screen and (min-width: 635px) {
    font-size: 1.2em !important;
  }
`;

const FirstBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
  padding: 0 10px;
  @media screen and (min-width: 425px) {
  }
  @media screen and (min-width: 635px) {
  }
`;

const ThirdBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-height: 730px) {
    padding-bottom: 150px;
  }
  @media screen and (min-width: 635px) {
    flex-direction: row;
  }
  @media screen and (min-width: 1200px) {
    padding-bottom: 0;
  }
`;

const H1Typography = styled.div`
  font-family: "Kanit";
  font-size: 50px;
  @media screen and (min-width: 425px) {
    font-size: 64px;
  }
  @media screen and (min-width: 635px) {
    font-size: 64px;
  }
`;

const H3Typography = styled.div`
  margin: 0 1em;
  font-family: "Kanit";
  font-weight: 800;
  @media screen and (min-width: 425px) {
    font-size: 24px;
  }
  @media screen and (min-width: 635px) {
    font-size: 48px;
  }
`;

const BoxPicture = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 200px;
  display: flex !important;
  align-items: flex-end;
  @media screen and (min-width: 635px) and (max-height: 375px) {
    width: 250px !important;
  }
  @media screen and (min-width: 425px) {
    width: 250px;
  }

  @media screen and (min-width: 635px) {
    width: 330px;
  }
  @media screen and (min-width: 992px) {
    width: 400px;
  }
  @media screen and (min-width: 1440px) {
    width: unset;
  }
`;

export default App;
